import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetOrganizationDetails,
  useDeleteOrganization,
} from "../apiCalls/userApiCalls";
import { ArrowBackIos, Edit, Delete } from "@mui/icons-material";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

export default function OrganizationDetails() {
  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser.token;
  const { orgId } = useParams();
  const navigate = useNavigate();

  const { isLoading: isOrgLoading, data: organizationDetails } =
    useGetOrganizationDetails(orgId, token);

  const {
    mutate: deleteOrganizationMutate,
    isLoading: isDeleteOrganizationLoading,
    isError: isDeleteOrganizationError,
    error: deleteOrganizationError,
  } = useDeleteOrganization();

  const handleEditOrganization = () => {
    navigate(`/editOrganization/${orgId}`);
  };

  const handleDeleteOrganization = () => {
    const data = {
      id: orgId,
      token: token,
    };
    deleteOrganizationMutate(data, {
      onSuccess: () => {
        navigate("/organizations");
      },
    });
  };

  return (
    <>
      {isOrgLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex w-full items-center justify-center">
            <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
              <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
                <button
                  onClick={() => window.history.back()}
                  className="w-full h-full rounded-full flex items-center justify-center"
                >
                  <ArrowBackIos
                    style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
                  />
                </button>
              </div>
              <div className="pt-10 flex flex-col gap-2 items-center">
                <div className="flex items-center gap-2">
                  <button
                    onClick={handleEditOrganization}
                    className="flex h-full mt-2 text-center px-4 py-2 text-xs font-medium tracking-wide hover:scale-105 transition-all bg-[#59c3c2] hover:bg-teal-600 border border-gray-100 rounded-lg text-white"
                  >
                    <Edit />
                    Edit
                  </button>
                  <button
                    onClick={handleDeleteOrganization}
                    className="flex h-full mt-2 text-center px-4 py-2 text-xs font-medium tracking-wide hover:scale-105 transition-all bg-[#cc3636] hover:bg-red-600 border border-gray-100 rounded-lg text-white"
                  >
                    <Delete />
                    Delete
                  </button>
                </div>
              </div>

              <form className="pb-8">
                <div className="space-y-3 pt-8">
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <div className="text-lg py-2 px-2 flex gap-2 border-b border-gray-500">
                      {organizationDetails.data.data.name}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label className="text-lg font-medium text-gray-500">
                      Overtime Threshold (hrs)
                    </label>
                    <div className="text-lg py-2 px-2 flex gap-2 border-b border-gray-500">
                      {organizationDetails.data.data.overtimeThreshold}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label className="text-lg font-medium text-gray-500">
                      Overtime Multiplier
                    </label>
                    <div className="text-lg py-2 px-2 flex gap-2 border-b border-gray-500">
                      {organizationDetails.data.data.overtimeMultiplier}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor="instanceUrl"
                    >
                      Salesforce Instance URL
                    </label>
                    <div className="py-4 px-5 flex gap-2 border-b border-gray-500">
                      {organizationDetails.data.data.salesforceInstanceUrl}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor="securityToken"
                    >
                      Salesforce User Security Token
                    </label>
                    <div className="py-4 px-5 flex gap-2 border-b border-gray-500">
                      {
                        organizationDetails.data.data
                          .salesforceUserSecurityToken
                      }
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor="username"
                    >
                      Salesforce Username
                    </label>
                    <div className="py-4 px-5 flex gap-2 border-b border-gray-500">
                      {organizationDetails.data.data.salesforceUsername}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor="password"
                    >
                      Salesforce User Password
                    </label>
                    <div className="py-4 px-5 flex gap-2 border-b border-gray-500">
                      {organizationDetails.data.data.salesforceUserPassword}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label
                      className="text-lg font-medium text-gray-500"
                      htmlFor="salesforceSyncEnabled"
                    >
                      Salesforce Sync Enabled
                    </label>
                    <div className="py-4 px-5 flex gap-2 border-b border-gray-500">
                      {organizationDetails.data.data.salesforceSyncEnabled
                        ? "Enabled"
                        : "Disabled"}
                    </div>
                  </div>
                  {/* Add more organization details here as needed */}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
